<template>
	<div class="pa-0 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<!-- 타이틀 -->
			<v-col
				align="center"
				class="pa-0"
			>
				<v-row class="ma-0 py-5 justify-center main_tit">
					<v-col
						class="pa-0"
						cols="auto"
						align-self="center"
					>
						<v-img
							width="24"
							eager
							:src="require('@/assets/img/careflo/icon_care_flo.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0 ml-1"
						align-self="center"
						cols="auto"
					>
						케어플로 서비스
					</v-col>
				</v-row>
			</v-col>

			<!-- 시공자 본인인증 -->
			<v-col
				cols="12"
				class="pa-4 mb-5 section"
			>
				<h3 class="mb-2">시공자 본인인증</h3>
				<p class="desc mb-4">시공자 본인 인증을 위해 휴대폰 번호를 입력해주세요</p>

				<!-- 휴대폰번호 -->
				<p class="mb-2 item">휴대폰번호</p>
				<v-row class="ma-0 mb-2">
					<v-col
						class="pa-0"
						cols="8"
					>
						<div class="mr-1">
							<CommonInputsInput05
								v-model="hand"
								rule-name="tel"
								:height="40"
								type="number"
								placeholder="휴대폰번호 (-없이 입력)"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
					>
						<CommonButtonsButton01
							class="pa-0 btn_confirm"
							:disabled="hand === null"
							name="인증번호 발송"
							:color="smsNumberCheck ? 'primary' : '#262626'"
							height="40"
							@click="checkSms(sms_check_type[12])"
						/>
						<v-col
							cols="12"
							class="pa-0 pr-1"
							align="end"
						>
							<p
								v-if="smsNumberCheck"
								style="font-size: 12px; color: #00ce7d"
							>
								인증되었습니다.
							</p>
						</v-col>
					</v-col>
				</v-row>
				<v-row
					v-if="smsNumberModel"
					class="ma-0"
				>
					<v-col
						class="pa-0"
						cols="8"
					>
						<div class="mr-1">
							<CommonInputsInput05
								v-model="smsNum"
								:height="40"
								type="number"
								placeholder="인증번호 입력"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
					>
						<CommonButtonsButton01
							class="btn_confirm"
							name="인증확인"
							color="#262626"
							:height="40"
							@click="confirmSmsNumber"
						/>
						<v-col
							cols="12"
							class="pa-0 pr-2 pt-1"
							align="end"
							style="color: #fa2a6f; font-size: 12px"
						>
							{{ sms_check_timer }}
						</v-col>
					</v-col>
					<CommonLoaderLine01
						v-if="telLoading"
						class="mt-1"
					/>
				</v-row>

				<v-expansion-panels
					v-if="CAREFLO_GET_AUTH_USER.event === 'Y'"
					v-model="panel"
					accordion
					elevation="0"
					class="agreement mt-4"
					multiple
				>
					<v-expansion-panel>
						<v-expansion-panel-header>
							<CommonCheckboxsCheckbox02
								v-model="privateTermsCheck"
								text="[필수] 개인정보 수집 이용 동의"
								class="pa-0 ma-0 careflo"
							/>
						</v-expansion-panel-header>

						<v-expansion-panel-content>
							<p class="mb-5 font_sm color_gray_8">
								동화자연마루는 마케팅 서비스 제공을 위해 개인정보 보호법에 의거하여 아래와 같은 개인정보를
								수집하고 있습니다.
							</p>
							<p class="mb-1 font_sm color_gray_8">1) 수집하려는 개인정보 항목 : 휴대폰번호</p>
							<p class="mb-1 font_sm color_gray_8">2) 개인정보의 수집 및 이용목적 : 이벤트 당첨자 경품 발송</p>
							<p class="mb-1 font_sm color_gray_8">3) 개인정보 보유 및 이용기간 : 이벤트 종료후 즉시 파기</p>
							<p class="mb-1 font_sm color_gray_8">4) 수탁자명 : 십일번가주식회사 비즈콘 (경품발송 업무 대행)</p>
							<p class="mb-1 font_sm color_gray_8">
								※ 개인정보수집 동의를 하지 않는 경우, 이벤트 참여가 불가합니다.
							</p>
							<v-btn
								class="btn_private"
								text
								@click="common_openPrivate"
							>
								개인정보처리방침확인
							</v-btn>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>

			<!-- 고객센터 정보 -->
			<v-col class="pa-0 px-4">
				<CommonButtonsButton01
					:disabled="valueCheck"
					class="mb-5 btn_clear"
					name="본인인증"
					color="#78C046"
					height="42"
					@click="confirm"
				/>

				<v-row
					v-if="CAREFLO_GET_AUTH_USER.event === 'Y'"
					class="ma-0 mb-5 justify-center"
				>
					<v-btn
						class="btn_event"
						text
						@click="eventDetailPop = true"
					>
						이벤트보기
					</v-btn>
				</v-row>

				<div class="mb-15 center_info">
					<v-row class="ma-0 justify-center">
						<v-col
							class="pa-0"
							cols="auto"
							align="end"
						>
							<div class="mr-1 txt_1">고객센터</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="auto"
						>
							<div class="ml-2">
								<v-col class="pa-0 txt_2">1899-2837</v-col>
								<v-col class="pa-0 txt_3">평일 9:00 ~ 17:00(공휴일 외)</v-col>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-container>

		<CommonDialogAppDialog
			:dialog="popupDialog"
			:items="popupItems"
			@openPopup="eventDetailPop = true"
			@close="popupDialog = false"
		/>
		<CommonDialogDialogDynamicBasic
			v-if="eventDetailPop"
			:dialog="eventDetailPop"
			title="이벤트 확인"
			components-path="home/careflo/eventPop"
			@close="close"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import common from '@/mixins/common'
import model from '@/mixins/model'
import sms from '@/mixins/sms'
export default {
	name: 'CareFloStoreLogin',
	mixins: [common, model, sms],

	data: () => ({
		privateTermsCheck: false,
		panel: null,

		popupDialog: false,
		popupItems: [],
		eventDetailPop: false,

		hand: null,
		smsNum: null,

		// sms
		smsNumberCheck: false,
		smsNumberModel: false,
		telLoading: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
		valueCheck() {
			if (this.CAREFLO_GET_AUTH_USER.event === 'Y') return !this.smsNumberCheck || !this.privateTermsCheck
			else return !this.smsNumberCheck
		},
	},
	watch: {
		sms_check_timer(val) {
			if (val === '00:00') {
				this.smsNumberCheck = false
				this.smsNumberModel = false
			}
		},
	},
	created() {},
	mounted() {
		const popup = this.$cookies.get('careflo_event_popup')
		if (popup !== 'false' && this.CAREFLO_GET_AUTH_USER.event === 'Y') {
			this.popupItems = [
				{
					popup_kind: 'IMAGE',
					popup_url: require('@/assets/img/careflo/careflo_event.png'),
					popuplink_url: 'popup',
				},
			]
		}
	},
	methods: {
		...mapMutations(['CAREFLO_MU_AUTH_USER']),
		async checkSms(type) {
			this.telLoading = true
			let params = {
				type: type,
				hand: this.hand,
				name: this.name,
				login_id: '',
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		confirm() {
			if (!this.smsNumberCheck) return
			const item = {
				store: true,
				hand: this.hand,
			}
			this.CAREFLO_MU_AUTH_USER(item)
			this.$router.push(`/careflo/store/scan`)
		},
		close(e) {
			this.eventDetailPop = false
			if (e.target.className === 'closeBtn') this.popupDialog = false
		},
	},
}
</script>

<style scoped lang="scss">
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.section {
	background-color: $color_white;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
		word-break: keep-all;
	}
	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.checkbox_group {
	min-height: 180px;
	border-radius: 8px;
	background-color: $color_gray_1;
}

.btn_clear {
	:deep(.v-btn__content) {
		color: $color_white;
		font-weight: $fw_bold;
		font-size: $font_lg;
	}
}

.btn_confirm {
	:deep(.v-btn__content) {
		font-size: $font_normal !important;
	}
}

.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

:deep(.v-text-field__details) {
	padding: 0 !important;
	margin: 0 !important;
}

// 약관동의
:deep(.theme--light.v-expansion-panels .v-expansion-panel) {
	background-color: $color_gray_1 !important;
	&::before {
		box-shadow: none !important;
	}
	&.v-expansion-panel--active {
		.v-expansion-panel-header {
			padding: 12px !important;
			&::after {
				display: block !important;
			}
		}
	}
}

.agreement {
	.v-expansion-panel-header {
		position: relative;
		min-height: 0 !important;
		padding: 4px 12px !important;
		&::after {
			display: none;
			width: 95%;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: rgba(0, 0, 0, 0.12);
			content: '';
		}
		.v-btn {
			min-width: 0 !important;
		}
	}
	.v-expansion-panel-content {
		padding: 12px !important;
		word-break: keep-all;
		:deep(.v-expansion-panel-content__wrap) {
			padding: 0 !important;
		}
		.btn_private {
			min-width: none !important;
			padding: 0 !important;
			:deep(.v-btn__content) {
				color: $color_font;
				font-size: $font_mid;
				border-bottom: 1px solid $color_font;
			}
		}
	}
}

.btn_event {
	min-width: none !important;
	padding: 0 !important;
	:deep(.v-btn__content) {
		color: $color_font;
		font-size: $font_normal;
		border-bottom: 1px solid $color_font;
	}
}
</style>
